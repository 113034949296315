import React from "react";

export default function Homepage() {
    return(
        <section>
            <h2>Welcome to Ashton Software Ltd.</h2>
            <p>I have worked in the Genomics and Bioinformatics fields since my PhD studies, 
               and have contributed to many large genome projects, 
                including <i>Schistosoma mansoni</i>, <i>Acyrthosiphon pisum</i> and many others.</p>
            <p>My original background was in commercial computing, working mostly on information retrieval 
               systems for large bibliographic databases.  When I retrained as a biologist, it was just at 
               the time that large DNA datasets were starting to be generated, and large scale genome 
               sequencing was becoming tractable.  This meant I was ideally placed to work with the emerging 
               technologies to help generate biological insights.</p>
            <p>I see myself as primarily a technologist, and for most of the last two decades I 
               have been passionate about enabling biological researchers to use the latest technologies 
               and methods to solve their biological problems and to generate novel results.</p>
            <p>We offer training in Python for any type of data analysis, as well as consultancy and 
               support for large scale bioinformatics projects.</p>
            <p>We can also undertake bespoke software development, specialising in web sites for 
               the presentation of large and complex datasets, 
            regardless of the domain of research they come from.</p>
        </section>
    )
}
