import './ashtonsoftware.css';
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import Footer from "./components/Footer";
import About from "./components/About";
import Training from "./components/Training";
import Development from "./components/Development";

function App() {
    return (
    <div className="App">
        <Header sectionnum="0" />
        <nav>
            <Link to="/" className="nav-item">Home</Link>
            <Link to="/about" className="nav-item">About Us</Link>
            <Link to="/training" className="nav-item">Training</Link>
            <Link to="/development" className="nav-item">Development</Link>
        </nav>
        <Routes>
            <Route path="/" element={<Homepage />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/training" element={<Training />}></Route>
            <Route path="/development" element={<Development />}></Route>
        </Routes>
        <Footer sectionnum="4" />
    </div>
  );
}

export default App;
