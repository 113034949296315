import React from "react";
import profilePic from "../assets/pete-profile-pic.png";

export default function About({sectionnum}) {
    return (
        <section>
            <h2>About Us</h2>
            <div>
                <img className="rightimage" src={profilePic} alt="Photograph of Peter Ashton" />
                <p>Ashton Software is based in Yorkshire and run by Dr Peter Ashton, who has worked in Scientific Computing for 
                over two decade, at the University of York, and at the Sanger Institute.  Most recently, he was 
                head of the Genomics and Bioinformatics Lab, within the Department of Biology's 
                Bioscience Technology Facility.</p>
                <p>Bioinformatics is a discipline which arose from the need for biologists to handle 
                very large data sets. Originally, these came from handling sequencing data, but now include 
                large scale data on gene expression, localisation and activity. Now, because the methods used 
                are completely general, bioinformaticians now deal with most biological data, everything from 
                processing images to plotting species distributions in different environments.  The methods 
                used, including machine learning, neural networks and deep learning are the core techniques used
                in all data science applicationa and so are not specific to 
                the biological domain.</p>
                <p className="below-image"></p>
            </div>
        </section>
    );
}
