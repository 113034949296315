import React from "react";
import CourseList from "./CourseList";
import pathway_courses from "./pathway_courses.json"
// import other_courses from "./other-courses.json";

export default function Training({sectionnum}) {
    return (
        <section>
            <h2>Training</h2>
            <p>We have been training as part of our roles at the University of York for over 15 years.  
            Our courses cover a wide range of topics, and we can develop custom course for your team.  
            Whilst we are biologists first, our Python courses have been taken by people working in a 
            range of fields from physics and computer science right through to archaeology and computational 
            linguistics.</p>
            <p>All of the courses share a common approach, not just getting through a set of pre-designed 
            exercises, but getting the delegates to really think about how they will apply what they are 
            learning to their own data and analytical approaches.</p>
            <p>Courses can be run on your own premises - all we need is a room with decent WiFi and for 
            each delegate to bring their own laptop, running whatever operating system they normally use.  
            Full details of any software that needs to be installed before the course will be sent in the 
            joining instruction, and we are happy to assist anyone who has any issues at the start of the 
            course.</p>
            <h3>Bioinformatics Coding Skills Pathway</h3>
            <p>The core of our training offering is a set of largely independent courses, that can be taken 
            in almost any order. There concentrate on a range of technical and coding skills that are required 
            to understand, run and develop bioinformatics tools. Whilst the courses tend to use biological data 
            sets to demonstrate the techniques that are being taught, the methods can be used on any other data
            type.</p>
            <CourseList courses={pathway_courses} />
            <p>We offer e-mail support for all of our course delegates, to make sure that the end of the 
            course is not the end of the learning process, and that what our students learn will be of use to 
            them in their careers for many years to come.</p>
            <p>If you want us to run a course for a group on your premises, please 
            contact <a href="mailto:admin@ashtonsoftware.co.uk">admin@ashtonsoftware.co.uk</a> for a quotation.  You 
            can also use this e-mail address to express interest in joining a shared course, and see if there are any 
            dates coming up soon.</p>
        </section>
    );
}

// <h3>Other Courses</h3>
// <p>Some of other the courses that we can run "off-the-shelf" are listed below. You can click on a course title to see more information.</p>
// <CourseList courses={other_courses} />
