import React from "react";

export default function CourseDetails({description, syllabus}) {
    return (
        <div className="coursedetails">
        {description.map((paragraph, i) => (
            <p key={i} className="description">{paragraph}</p>
        ))}
        <p>Topics covered include:</p>
        <ul className="syllabus">
        {syllabus.map((item, i) => (
            <li key={i} className="syllabusitem">{item}</li>
        ))}
        </ul>
        </div>
    );
}