import React from "react";
import logo from "../assets/EmailLogo.png";

export default function Header() {
    return (
        <header>
            <img src={logo} className='mainLogo' alt="Logo for the company Ashton Software Ltd, containing a diagram of a network, and the words Ashton Software" />
            <h2 className="rightHeader">Bespoke Software Development<br /> and Training</h2>
        </header>
    )
}
