import React, { useState } from "react";
import { FaCaretRight, FaCaretDown } from "react-icons/fa";

import CourseDetails from "./CourseDetails";

export default function Course({courseID, coursename, description, syllabus}) {
    const [visible, setVisible] = useState(false);
    
    const toggleDetails = (e) => {
        setVisible(!visible);
    }

    if (visible) {
        return (
            <>
            <FaCaretDown color="#308088" onClick={toggleDetails} size="1.4em" /><h3 className="coursename" onClick={toggleDetails}>{coursename}</h3><br />
            <CourseDetails description={description} syllabus={syllabus}/>
            </>
        );    
    } else {
        return (
            <>
            <FaCaretRight color="#308088" onClick={toggleDetails} size="1.4em"/><h3 className="coursename" onClick={toggleDetails}>{coursename}</h3><br />
            </>
        );
    }
}
