import React from "react";
import Course from "./Course";

export default function CourseList({courses}) {
    return (
        <div className="courselist">
            {courses.map((course, i) => (
                <Course key={i} courseID={i} {...course} />
            ))}
        </div>
    );
}