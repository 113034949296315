import React from 'react';

// boilerplate text for the foot of every web page

const start_year = "2022";
let end_year = "";
let this_year = String(new Date().getFullYear());
if (this_year !== start_year) {
    end_year = "-" + this_year;
}

export default function Footer() {
    return (
        <footer>
            <p className="smallprint">&copy; {start_year}{end_year} Ashton Software Ltd, 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ.  Registered Company Number: 14363535</p>
        </footer>
    )
}
