import React from "react";

export default function Development({sectionnum}) {
    return (
        <section>
            <h2>Development Services</h2>
            <p>
                We have extensive experience of development data based applications.  Most application now are web-based, even
                if private, and use a range of database system to house the data.  In most cases, we provide a Python back-end 
                to serve individual data requests, and a javascript single page application using framework like React.js to 
                render the visualisations of the data.
            </p>
            <p>
                This approach provides a flexible means of splitting the workload for most analyses between the client- and 
                server-sides of the system.  Additionally, where most analyses can be performed in advance, e.g., on an HPC system,
                it provides a fast, responsive application for the use, with a rich user experience.
            </p>
            <p>
                Prices for this kind of work can vary tremendously, depending on the complexity of the analyses that are required.  
                If you are interested in this for your project, contact us 
                on <a href="mailto:admin@ashtonsoftware.co.uk">admin@ashtonsoftware.co.uk</a>.
            </p>
        </section>
    );
}
